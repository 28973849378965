// Main Firebase Imports
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB1,
    authDomain: process.env.REACT_APP_FB2,
    projectId: process.env.REACT_APP_FB3,
    storageBucket: process.env.REACT_APP_FB4,
    messagingSenderId: process.env.REACT_APP_FB5,
    appId: process.env.REACT_APP_FB6,
    measurementId: process.env.REACT_APP_FB7
}

const myFirebase = firebase.initializeApp(firebaseConfig);
export default myFirebase;
export const auth = firebase.auth()