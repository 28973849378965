import React from 'react'
import { Link } from 'react-router-dom';
import MySketch from './MySketch';

export default function LandingPage() {
    return (
        <div id="app-main-container">
            <section id="top-container">
                <div id="outer-header-wrapper">
                    <div id="inner-header-wrapper">
                        <h1 id="main-title">Filli Go</h1>
                        <h4 id="main-description">Saves you time so you could save money.</h4>
                        <Link to="/beta-signup">
                            <button id="sign-up-now-main-bttn">Signup For Our Beta Today</button>
                        </Link>
                    </div>
                </div>
                <MySketch />
            </section>
            <section id="bottom-container">
                <article>
                    <h5 className="bottom-container-subheader">Built by professionals <span className="emphasis-txt">for
                        professionals</span></h5>
                    <div>
                        <p>As industry experts for over 25 years, we know how important projects can make you forget simple
                        equipment and supplies. As a result, time is wasted as key employees leave job sites to go buy
                        something as simple as a bag of nails.</p>
                        <p>We let you focus on the heavy lifting as we are the ones who bring these simple, yet key supplies
                        directly to you. Our <span className="emphasis-txt">Filli Go App </span> allows our users to just
                        select and purchase supplies and have them delivered that same workday!</p>
                        <p>Stop leaving the job sites, and let us get the tools directly to you.</p>
                    </div>
                </article>
                <article>
                    <h5 className="bottom-container-subheader">Our offerings are <span className="emphasis-txt">superb</span> in
                    every sense of the word</h5>
                    <div>
                        <p>Our original experience comes from working in and around NYC region. We plan to launch our app
                        and services starting in NYC to serve the everincreasing construction industry.</p>
                        <p>New York is all about hustle and getting shit done. This makes it the perfect city for us to
                        focus in on as we feel it has the right mindset and currently the victims of these simply
                        forgotten mistakes.</p>
                    </div>
                </article>
                <article id="signup-container">
                    <h5 id="signup-container-subheader">Be apart of our eagerly waiting customerbase</h5>
                    <Link id="sign-up-now-bottom-container-bttn" to="/beta-signup">
                        <button >Signup For Our Beta Today</button>
                    </Link>
                </article>
            </section>
            <footer id="footer-container">
                <h5>Made with &#x2665; by the Filli Go Team</h5>
                <h6>all ® rights reserved by Filli Go</h6>
            </footer>
        </div>
    )
}
