import React, { useState } from 'react'
import Sketch from 'react-p5'


class Bubble {
    constructor(p5) {
        this.startUp(p5)
        this.x = p5.random(-50, p5.width)
    }
    startUp(p5) {
        this.color = {
            r: p5.random(1, 255),
            g: p5.random(1, 100),
            b: p5.random(50, 100),
        }
        this.x = -100
        this.y = p5.random(0, p5.height - 10)
        this.size = p5.random(50, 100)
        this.speed = p5.random(0.5, 1.5)
    }
    display(p5) {
        p5.noStroke()
        let distanceFromEdge
        if (this.x < p5.width - this.x) {
            distanceFromEdge = this.x + 100
        } else { distanceFromEdge = p5.width - this.x }
        p5.fill(this.color.r, this.color.g, this.color.b, p5.map(distanceFromEdge - this.size / 2, 0, p5.width / 2, 60, 95))
        p5.rect(this.x, this.y, this.size, this.size / 16)
        this.x += this.speed
    }
}

export default function MySketch() {

    const [bubbles, changeBubbles] = useState([])

    function populateBubbles(amount, p5) {
        let bubbleQuant = amount
        if (amount > 400) { bubbleQuant = 400 }
        let newBubbles = []
        for (let i = 0; i < bubbleQuant; i++) {
            newBubbles.push(new Bubble(p5))
        }
        changeBubbles([...newBubbles])
    }

    return (
        <Sketch
            setup={(p5) => {
                p5.createCanvas(document.getElementById("app-main-container").clientWidth, 500)
                populateBubbles(400, p5)
            }}
            draw={(p5) => {
                p5.background("black")
                let currentWidth = document.getElementById("app-main-container").clientWidth
                if (p5.width !== currentWidth) {
                    p5.resizeCanvas(currentWidth, 500)
                    populateBubbles(Math.floor(currentWidth / 3), p5)
                }
                bubbles.forEach(eachBubble => {
                    eachBubble.display(p5)
                    if (eachBubble.x - 100 >= p5.width) { // resets to start
                        eachBubble.startUp(p5)
                    }
                })
            }}
        />
    )
}
