import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import myFirebase from "./firebase"
import ReCAPTCHA from "react-google-recaptcha";

// Form Page
export default function FormPage() {

    // Form Values
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    })
    const [captchaValue, setCaptchaValue] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState(false)

    const [loading, setLoading] = useState(false)

    // Triggers the submit of form
    async function submitBetaRequest(e) {
        e.preventDefault()
        if (form.name.length === 0 || form.email.length === 0) {
            setErrorMessage("You must input a name and an email!")
            return
        }
        if (captchaValue === false) {
            setErrorMessage("You must complete the captcha!")
            return
        }
        try { // proceeds with submittion
            setLoading(true)
            await myFirebase.firestore().collection('beta-requests')
                .add({ ...form, timestamp: new Date() })
            setSuccess(true)
        } catch (error) { console.log(error); setErrorMessage("There was a problem submitting the form!") }
        setLoading(false)
    }

    // Once form is successfully submitted
    if (success) {
        return (
            <div id="form-page-outer-container">
                <div id="form-successfully-submitted" >
                    <h1 id="form-success-header">Form Successfully Submitted!</h1>
                    <p>Thank you for your interest! We will keep you updated on our progress :)</p>
                    <Link id="form-main-container-bttn" to="/">
                        <button>Return to Landing Page</button>
                    </Link>
                </div>
            </div>
        )
    }

    // If form not filled out yet (default)
    return (
        <div id="form-page-outer-container">
            <form id="form-page-inner-container" onSubmit={(e) => submitBetaRequest(e)}>
                <Link id="go-back-bttn" to="/">
                    <button >Back</button>
                </Link>
                <h2 id="contact-us-header">Contact Us</h2>
                <p>Want to get our product updates? Hear about our success? Just curious? Fill out the contact form!</p>
                <div className="input-wrapper">
                    <label>Name <span className="required-input">*</span></label>
                    <input
                        placeholder="John Doe"
                        autoComplete="off"
                        type="text"
                        disabled={loading}
                        value={form.name}
                        maxLength={32}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                    />
                </div>
                <div className="input-wrapper">
                    <label>Email <span className="required-input">*</span></label>
                    <input
                        placeholder="myEmail@gmail.com"
                        autoComplete="off"
                        type="email"
                        disabled={loading}
                        value={form.email}
                        maxLength={32}
                        onChange={(e) => setForm({ ...form, email: e.target.value })} />
                </div>
                <div className="input-wrapper">
                    <label>Message</label>
                    <textarea
                        placeholder="I am really excited...!"
                        autoComplete="off"
                        type="text"
                        disabled={loading}
                        value={form.message}
                        maxLength={240}
                        onChange={(e) => setForm({ ...form, message: e.target.value })} />
                </div>
                <ReCAPTCHA
                    sitekey="6LeemVUdAAAAAA34BwOLgf0Gp7hJEiT5iALX6hBm"
                    onChange={setCaptchaValue}
                />
                <div className="error-message">{errorMessage}</div>
                <div id="form-main-container-bttn">
                    <button type="submit" disabled={loading}>Submit</button>
                </div>
            </form>
        </div>
    )
}
